
  .dashboard {
    width: 100vw;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr;
    /* position: absolute; */
    /* padding: 5%; */
    box-sizing: border-box;
  }
 
.btn{
  border: none;
  background-color: red;
  color: white;
  font: 300 0.9vmax ;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}
  .logininput{
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    outline: none;
  }
  .loginForm {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 2vmax;
    justify-content: space-evenly;
    height: 70%;
    transition: all 0.5s;
  }
  

  .loginForm > div {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .loginForm > div > svg{
    position: absolute;
    transform: translateX(1vmax);
    font-size: 1.6vmax;
    color: rgba(0, 0, 0, 0.623);
  }
  
  .loginForm > a {
    color: rgba(0, 0, 0, 0.651);
    text-decoration: none;
    align-self: flex-end;
    transition: all 0.5s;
    font: 500 0.8vmax ;
  }
  
  .loginForm > a:hover {
    color: black;
  }
  


  .loginBtn{
    border: none;
    background-color: red;
    color: white;
    font: 300 0.9vmax ;
    width: 100%;
    padding: 0.8vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
  }
  
  .loginBtn:hover {
    background-color: red;
  }
  

  @media screen and (max-width: 600px) {
    .LoginSignUpContainer {
      background-color: white;
    }
    .LoginSignUpBox {
      width: 100vw;
      height: 95vh;
    }

    .login_signUp_toggle > p {
      font: 300 1.5vmax ;
    }
  
    .loginForm {
      padding: 5vmax;
    }
  
    .loginForm > div > input {
      padding: 2.5vmax 5vmax;
      font: 300 1.7vmax ;
    }
  
    .loginForm > div > svg {
      font-size: 2.8vmax;
    }
  
    .loginForm > a {
      font: 500 1.8vmax ;
    }
  

    .loginBtn {
      font: 300 1.9vmax ;
      padding: 1.8vmax;
    }
  
    .dashboard {
      grid-template-columns: 1fr;
    }
  
    .dashboardContainer {
      border-left: none;
    }
  }

  
