.mapp {
  width: 100vw;
  max-width: 100%;
  display: grid;

}

.mappcontener {
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  background-color: rgb(255, 255, 255);
  max-height: 500px;
  overflow: auto;
  padding: 3rem 0;
}

.tree-node:not(.root)::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 10px;
  background-color: #000;
  top: -10px;
  left: 50%;
  transform: translateX(-1px);
}



.tree-node {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  position: relative;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 5px;
}

.node-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.children-container {
  display: flex;
  margin-top: 10px;
}

.mlm-tree {
  text-align: center;
}



@media screen and (max-width: 600px) {
  .tree-node:not(.root)::before {
    top: -5px; /* Adjust position for smaller screens */
    height: 5px; /* Adjust height for smaller screens */
  }

  .tree-node {
    margin: 5px 0; /* Adjust margin for smaller screens */
  }

  .children-container {
    margin-top: 5px; /* Adjust margin for smaller screens */
  }
}