/* incomedistribute.css */

.mapp {
    display: flex;
  }
  
  .mappcontener {
    padding: 20px;
    flex: 1;
  }
  
  .coin {
    font-size: 16px; /* Adjusted font size */
    margin-bottom: 15px; /* Reduced margin */
    color: #333;
  }
  
  .paymentreq {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px; /* Reduced gap */
  }
  
  .paymentreq input {
    padding: 8px; /* Reduced padding */
    font-size: 14px; /* Adjusted font size */
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
  }
  
  .signUpBtns {
    background-color: red;
    color: white;
    border: none;
    padding: 8px; /* Reduced padding */
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px; /* Adjusted font size */
  }
  
  .signUpBtns:hover {
    background-color: darkred;
  }
  