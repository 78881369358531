/* Original styles for larger screens */

.sidebar {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
}

.sidebar > a:first-child {
  padding: 0;
}
.sidebar > a > img {
  width: 100%;
  transition: all 0.5s;
}

.sidebar > a > img:hover {
  filter: drop-shadow(0 0 10px tomato);
}
.sidebar a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.493);
  font: 200 1rem;
  padding: 2rem;
  transition: all 0.5s;
}
.sidebar a:hover {
  color: tomato;
  transform: scale(1.1);
}

.sidebar a > P {
  display: flex;
  align-items: center;
}
.sidebar a > p > svg {
  margin-right: 0.5rem;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    padding: 2rem 0; /* Adjusted padding for smaller screens */
  }

  .sidebar a {
    font-size: 0.8rem; /* Adjusted font size for smaller screens */
    padding: 1rem; /* Adjusted padding for smaller screens */
  }

  .sidebar > a > img {
    width: 80%; /* Adjusted image width for smaller screens */
  }
}
