/* history.css */

.mapp {
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  box-sizing: border-box;
}

.mappcontener {
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  background-color: rgb(255, 255, 255);
  padding: 3rem 0;
}

.history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Style for the DataGrid */
.data-grid-container {
  flex: 1;
  width: 100%;
}

/* Override default styles of the DataGrid */
.MuiDataGrid-root {
  border: 1px solid #ddd;
}

.MuiTable-root {
  font-size: 14px;
}

.MuiTableCell-root {
  border-right: 1px solid #ddd;
}

/* Add some padding to the DataGrid header */
.MuiDataGrid-colCell {
  padding: 12px;
}

/* Style for the pagination */
.MuiTablePagination-root {
  margin-top: 16px;
}
