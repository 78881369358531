.term_page{
    box-sizing: border-box;
    padding: 5%;
}

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.term_page > p {
    text-align: center;
    font-size: 16px;
}
.term_page >h1 {
    text-align: center; 
}
.term_page > h4{
    text-align: center;
}

.email{
    text-decoration: none;
}

@media (max-width: 600px) {
    .term_page{
        box-sizing: border-box;
        padding: 5%;
    }
    .term_page > p {
        text-align: center;
        font-size: 16px;
    }
    .term_page >h1 {
        text-align: center; 
    }
    .term_page > h4{
        text-align: center;
    }
    
    .email{
        text-decoration: none;
    }
}
