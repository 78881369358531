/* Styling for the navigation bar */
.navbar {
    background-color: red;
    display: flex;
    justify-content: space-between;
    position: sticky;
    align-items: center;
    padding: 10px 20px;
    /* gap: 2rem; */
    
  }

  nav .title {
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}
/* .navbar ul {
  display: flex;
justify-content: center;
  align-items: center; 
  list-style: none;
  margin: 0;
  padding: 0;
} 
 
.navbar li {
  margin: 0 10px; /* Adjust the margin as needed */
 /* list-style: none;  */
 /* } */ 
 
nav ul{
    display: flex;
    justify-content: center;
  align-items: center; 
  list-style: none;
  margin: 0;
  padding: 0;
  }

  nav ul li{
    list-style: none;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    
  }

  .navbar .logo {
    font-weight: bold;
    font-size: 24px;
  }
  
  .navbar .icons {
    display: flexbox;
    align-items: center;
  }
  
  .navbar .icons a {
    color: white;
    text-decoration: none;
    margin-right: 15px;
  }
  
  /* Styling for the icons */
  .navbar .icons i {
    font-size: 20px;
  }
  
  .speedDial {
    position: fixed;
    right: 1vmax;
    top: 4.2vmax;
  }
  
  .speedDialIcon {
    width: 56px;
    height: 56px;
    border-radius: 60%;
  }
  
  
/* src/components/DropdownButton.css */
.dropdown {
  position: relative;
  display: inline-block;
  color: white;
}

.dropdown-toggle {
  display: absolute;
  background-color: red;
  border: 1px solid red;
  /* padding: 10px; */
  cursor: pointer;
}

.dropdown-content {
  display: block;
  position: absolute;
  padding: 1vmax;
  top: 100%; 
  /* Positions the dropdown below the button */
  left: 0;
  background-color: red;
  border: 1px solid red;
  border-top: none; /* Remove border at the top to connect with the button */
  /* min-width: 1px; */
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px ;
  text-decoration: none;
  display: block;
}

/* Style for when hovering over items (optional) */
.dropdown-content a:hover {
  background-color: rgb(223, 30, 30);
}


nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}


@media (max-width: 600px) {
  nav .menu {
      display: flex;
      justify-content: space-between;
     
  }

  nav {
      flex-direction: column;
      align-items: flex-start;
  }

  nav ul {
      display: none;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.25rem;
  }

  nav ul.open {
      display: flex;
  }

  nav ul li {
      width: 100%;
      text-align: center;
  }

  nav ul li a {
      margin: 0.2rem 0.5rem;
  }
  
}