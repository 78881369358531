.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; /* Set the height to 100% of the viewport height for vertical centering */
}

.paymentFormBtn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #de0909;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-top: 10px; /* Add margin to space out from the "Powered by Razorpay" message */
}

.poweredByRazorpay {
  margin-top: 20px;
  color: #666;
  font-size: 14px;
}

.paymentFormBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .paymentForm {
    width: 90%;
  }

  .paymentForm > p {
    font: 400 8vw ;
    padding: 4vw 0;
    width: 60%;
  }

  .paymentForm > div {
    margin: 10vw 0;
  }

  .paymentInput {
    padding: 4vw 10vw;
  }

  .paymentForm > div > svg {
    font-size: 6vw;
  }

  .paymentFormBtn {
    font: 300 4vw ;
    padding: 4vw;
  }
}
